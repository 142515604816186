import '/src/scss/style.scss';
import * as bodyScrollLock from 'body-scroll-lock';
import { initForm } from '/src/js/form.js';
import * as fieldDependencies from '/src/js/compositeFieldDependencies.js';
import { initAds, resizeAds } from '/src/js/adsManager.js';
import { initModal } from '/src/js/modal.js';
import { initAccordable, resizeAccordable } from '/src/js/accordable.js';
import { initMemberCheck } from '/src/js/memberCheck.js';
import { collapsible } from '/src/js/collapsible.js';
import { register } from 'swiper/element/bundle';
import SlimSelect from 'slim-select';
import { Datepicker } from 'vanillajs-datepicker';
import no from 'vanillajs-datepicker/locales/no';

Object.assign(Datepicker.locales, no);

document.addEventListener('DOMContentLoaded', function () {
  const elSiteHeader = document.getElementById('SiteHeader');


  /** RESIZE =================================================================*/
  window.addEventListener(
    'resize',
    debounce(function () {
      // resize code here...
      resizeAccordable();
      resizeAds();
    })
  );

  /** SCROLL =================================================================*/
  window.addEventListener(
    'scroll',
    throttle(function () {
      //siteHeaderState();
    })
  );

  /** SWIPER ===================================================================*/
  register();

  const swipers = document.querySelectorAll('swiper-container');
  swipers.forEach((elSwiper) => {
    const params = {
      // Optional parameters
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      breakpoints: {
        590: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2.5,
        },
        1140: {
          slidesPerView: 3.5,
        }
      }
    };

    Object.assign(elSwiper, params);

    elSwiper.initialize();

    const nextBtn = elSwiper.nextElementSibling.querySelector('.nextBtn');
    const prevBtn = elSwiper.nextElementSibling.querySelector('.prevBtn');


    nextBtn.addEventListener('click', function () {
      elSwiper.swiper.slideNext();
    });

    prevBtn.addEventListener('click', function () {
      elSwiper.swiper.slidePrev();
    });
  });

  /** FORM ===================================================================*/
  initForm();

  /** FORM FIELD COMPOSITE DEPENDENCIES ======================================*/
  fieldDependencies.init();

  /** MODAL ==================================================================*/
  initModal();

  /** ADS ====================================================================*/
  initAds();

  /** ACCORDABLE =============================================================*/
  initAccordable();

  /** collapsible =============================================================*/
  collapsible();

  /** MEMBER CHECK ===========================================================*/
  initMemberCheck();

  /** SITEHEADER::STATE ======================================================*/
  function siteHeaderState() {
    if (elSiteHeader) {
      const cssClass = 'siteheader--compress';

      if (window.pageYOffset > 64) {
        elSiteHeader.classList.add(cssClass);
      } else {
        elSiteHeader.classList.remove(cssClass);
      }
    }
  }
  siteHeaderState();

  /** DEBOUNCE ===============================================================*/
  function debounce(func, wait = 200) {
    let timeout = null;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }

  /** THROTTLE ===============================================================*/
  function throttle(func, wait = 200) {
    let timer = null;
    return function (...args) {
      if (timer === null) {
        timer = setTimeout(() => {
          func.apply(this, args);
          timer = null;
        }, wait);
      }
    };
  }

  /** VENDOR: BODY_SCROLL_LOCK ===============================================*/
  if (bodyScrollLock) {
    bodyScrollLock.clearAllBodyScrollLocks();
    var overlays = document.querySelectorAll('.reveal');

    for (var i = 0; i < overlays.length; i++) {
      var controller = overlays[i].querySelector('.reveal__controller');
      controller.addEventListener('change', onChangeController);
    }

    function onChangeController(event) {
      var parentNode = event.target.parentNode;
      for (var i = 0; i < overlays.length; i++) {
        if (overlays[i] !== parentNode) {
          let inputElement = overlays[i].querySelector('.reveal__controller');
          inputElement.checked = false;
        }
      }

      var panel = parentNode.querySelector('.reveal__panel');
      if (event.target.checked) {
        bodyScrollLock.disableBodyScroll(panel);
        document.body.classList.add('overlaid');
      } else {
        bodyScrollLock.clearAllBodyScrollLocks();
        document.body.classList.remove('overlaid');
        panel.scrollTop = 0;
      }
    }
  }

  /** VENDOR: SLIM_SELECT ====================================================*/
  if (SlimSelect) {
    var arrSelects = document.querySelectorAll('.form__select select');
    for (var i = 0; i < arrSelects.length; i++) {
      var elSelect = arrSelects[i];
      var showSearch = elSelect.dataset.showSearch === 'true';
      var placeholder = elSelect.dataset.placeholderValue || 'Select Value';
      var searchText = elSelect.dataset.searchText || 'No Results';
      var searchPlaceholder = elSelect.dataset.searchPlaceholder || 'Search';

      new SlimSelect({
        select: elSelect,
        placeholder,
        settings: {
          showSearch,
          searchText,
          searchPlaceholder,
        },
      });
    }
  }

  /** VENDOR: DATEPICKER =====================================================*/
  if (Datepicker) {
    var arrDatepickers = document.querySelectorAll('.form__date');
    for (var i = 0; i < arrDatepickers.length; i++) {
      var elDatepicker = arrDatepickers[i];
      var pickLevel = elDatepicker.classList.contains('form__date--month')
        ? 1
        : 0;
      var format = elDatepicker.classList.contains('form__date--month')
        ? 'yyyy-mm'
        : 'yyyy-mm-dd';

      new Datepicker(elDatepicker, {
        pickLevel,
        format,
        autohide: true,
        language: 'no',
        prevArrow: '&lsaquo;',
        nextArrow: '&rsaquo;',
        minDate: elDatepicker.getAttribute('min'),
        maxDate: elDatepicker.getAttribute('max'),
      });
    }
  }
});

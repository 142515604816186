export function initFileDrop() {
  var fileDrops = document.querySelectorAll('.form__file input[type="file"]');
  for(var i = 0; i < fileDrops.length; i++) {
    fileDrops[i].addEventListener('change', onFileDropChange);
  }
}

function onFileDropChange(event) {
  renderFileDropList(event.target);
}

function onFileDropItemClick(event) {
  var input = event.target.closest('.form__file').querySelector('input[type="file"]');
  var item = event.target.closest('li');
  var files = getFileDropFiles(input);
  var newFiles = [];

  for(var i = 0; i < files.length; i++) {
    if(files[i].name == item.textContent) {
      event.target.removeEventListener('click', onFileDropItemClick);
    } else {
      newFiles.push(files[i]);
    }
  }

  setFileDropFiles(input, newFiles);

  renderFileDropList(input);
}

function renderFileDropList(input) {
  var files = input.files;
  var elList = input.parentElement.querySelector('ul');

  if(files.length < 1 && elList) {
    elList.parentNode.removeChild(elList);
  }

  if(!elList) {
    elList = document.createElement('ul');
    input.parentElement.appendChild(elList);
  } else {
    for(var i = 0; i < elList.children.length; i++) {
      elList.children[i].removeEventListener('click', onFileDropItemClick);
    }
    elList.textContent = '';
  }
  
  for(var i = 0; i < files.length; i++) {
    var elItem = document.createElement('li');
    var elRemoveItem = document.createElement('i');
    elItem.textContent = files[i].name;
    elRemoveItem.addEventListener('click', onFileDropItemClick);
    elItem.appendChild(elRemoveItem);
    elList.appendChild(elItem);
  }
}

function getFileDropFiles(input){
  const files = new Array(input.files.length)
  for(var i = 0; i < input.files.length; i++) {
    files[i] = input.files.item(i);
  }
  return files;
}

function setFileDropFiles(input, files){
  const dataTransfer = new DataTransfer();
  for(const file of files) {
    dataTransfer.items.add(file);
  }
  input.files = dataTransfer.files;
}
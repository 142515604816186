export function initModal() {
  const openers = document.querySelectorAll('.js-modal-opener');
  for (var i = 0; i < openers.length; i++) {
    openers[i].addEventListener('click', onClickModalOpen);
  }

  const closers = document.querySelectorAll('.modal__bgd, .js-modal-closer')
  for (var i = 0; i < closers.length; i++) {
    closers[i].addEventListener('click', onClickModalClose);
  }

  document.addEventListener('keydown', onKeydownModalClose);
}


function openModal(element) {
  element.classList.add('modal--open');
  document.body.classList.add('has-open-modal');
}


function closeModal(element) {
  element.classList.remove('modal--open');
  document.body.classList.remove('has-open-modal');
}


function closeAllModals() {
  const modals = document.querySelectorAll('.modal');

  for (var i = 0; i < modals.length; i++) {
    closeModal(modals[i]);
  }
}


function onClickModalOpen(event) {
  event.preventDefault();

  var target = event.currentTarget.getAttribute('href');
  target = target.charAt(0) == '#' ? target.substring(1) : target;

  const modal = document.getElementById(target);

  if (modal) {
    openModal(modal);
  }
}


function onClickModalClose(event) {
  event.preventDefault();

  const modal = event.currentTarget.closest('.modal');

  if (modal) {
    closeModal(modal);
  }
}


function onKeydownModalClose(event) {
  const e = event || window.event;

  // Escape
  if (e.keyCode === 27) {
    closeAllModals();
  }
}

import { initFileDrop } from '/src/js/fileDrop.js';

export function initForm() {
  initFileDrop();
  initStepForm();
  initFieldDependencies();
  initDateOfBirth();
  initTextAreas();
  initSubmits();
}

/** MULTI STEP
*******************************************************************************/
function initStepForm() {
  var elForm = document.querySelector('.form--stepped');

  if (!elForm) {
    return;
  }

  var elSteps = elForm.querySelectorAll('.form__step-panel');
  for (var i = 0; i < elSteps.length; i++) {
    var elStep = elSteps[i];
    var elValidatables = elStep.querySelectorAll('.form__input, .form__select select, .form__textarea, .form__checkbox');

    var elProceed = elStep.querySelector('.form__step-proceed');
    elProceed.stepIndex = i;
    elProceed.addEventListener('click', function (event) {
      var idx = event.currentTarget.stepIndex;

      // prevalidate next step
      if (idx < elSteps.length - 1) {
        var nextStep = elSteps[idx + 1];
        var nextValidatables = nextStep.querySelectorAll('.form__input, .form__select select, .form__textarea, .form__checkbox');
        validateStep(nextStep, nextValidatables);
				elForm.scrollIntoView({behavior: 'smooth'});
      }
    })

    validateStep(elStep, elValidatables);

    for (var j = 0; j < elValidatables.length; j++) {
      var elValidatable = elValidatables[j];
      elValidatable.addEventListener('input', validateStep.bind(null, elStep, elValidatables));
      elValidatable.addEventListener('change', validateStep.bind(null, elStep, elValidatables));
      elValidatable.addEventListener('changeDate', validateStep.bind(null, elStep, elValidatables));
      elValidatable.addEventListener('blur', onBlurValidatable);
    }
  }
}

function validateStep(elStep, elValidatables) {
  var isValidStep = true;

  for (var i = 0; i < elValidatables.length; i++) {
    var elValidatable = elValidatables[i];

    if (!elValidatable.disabled && !elValidatable.checkValidity()) {
      isValidStep = false;
    }
  }

  renderStepValidity(elStep, isValidStep);
}

function renderStepValidity(elStep, isValidStep) {
  var elForm = elStep.closest('.form--stepped');
  var elProceed = elStep.querySelector('.form__step-proceed');

  if (elProceed) {
    var elController = elForm.querySelector('#' + elProceed.getAttribute('for')) || elProceed;
    elController.disabled = !isValidStep;
    elProceed.classList.toggle('disabled', !isValidStep);
  }
}

function onBlurValidatable(event) {
  event.currentTarget.classList.add('visited');
}

/** Date of Birth
*******************************************************************************/
function initDateOfBirth() {
  var elDobController = document.querySelector('input[name="dobval"]');
  if (elDobController) {
    var elDobInput = elDobController.parentElement.previousElementSibling.querySelector('input');
    if (elDobInput) {
      elDobInput.addEventListener('change', function (event) {
        onChangeDobInput(
          event.target.value.substring(0, 6),
          elDobController
        );
      }, false);
    }
  }
}

function onChangeDobInput(value, elController) {
  if (value.length == 6) {
    var dateNow = new Date();
    var dob = {
      year: value.substring(4, 6),
      monthIndex: Number(value.substring(2, 4)) - 1,
      day: Number(value.substring(0, 2)),
    };

    // Compare and adjust for millenium
    if (dob.year < (dateNow.getFullYear() - 2000)) {
      dob.year = '20' + dob.year;
    }

    var dateBirth = new Date(
      dob.year,
      dob.monthIndex,
      dob.day
    );

    var dateYoung = new Date(
      dateBirth.getFullYear() + 35,
      dob.monthIndex,
      dob.day
    );

    if (dateNow < dateYoung) {
      elController.value = 'young';
    }
    else {
      elController.value = '';
    }

    elController.dispatchEvent(new Event('change'));
  }
}

/** TEXTAREA LIMITOR
*******************************************************************************/
function initTextAreas() {
  var elTextAreas = document.querySelectorAll('textarea[maxlength]');
  for (var i = 0; i < elTextAreas.length; i++) {
    elTextAreas[i].addEventListener('input', function (event) {
      const elTarget = event.target;
      const elCounter = elTarget.nextElementSibling;

      if (elCounter.classList.contains('counter')) {
        elCounter.innerText = elTarget.value.length + '/' + elTarget.getAttribute('maxlength');
      }
    });
  }
}


/** FIELD DEPENDENCIES
*******************************************************************************/
function initFieldDependencies() {
  var dependents = document.querySelectorAll('[data-dependency-name]');

  for (var i = 0; i < dependents.length; i++) {
    var dependent = dependents[i];
    var dependencyName = dependent.dataset.dependencyName || null;
    var dependencyValue = dependent.dataset.dependencyValue || null;

    if (dependencyName && dependencyValue) {
      var dependees = document.querySelectorAll('[name="' + dependencyName + '"]');
      for (var j = 0; j < dependees.length; j++) {
        dependees[j].addEventListener('change', onChangeDependee);
        setDependencyState(dependees[j]);
      }
    }
  }
}

function onChangeDependee(event) {
  setDependencyState(event.currentTarget);
}

function setDependencyState(dependee) {
  var dependeeName = dependee.name;
  var dependeeValue = dependee.value == 'on' ? dependee.checked.toString() : dependee.value;

  // Handle radio
  dependeeValue = dependee.type == 'radio'
    ? document.querySelector('input[name="' + dependeeName + '"]:checked').value
    : dependeeValue;

  var dependents = document.querySelectorAll('[data-dependency-name="' + dependeeName + '"]');

  for (var i = 0; i < dependents.length; i++) {
    var dependent = dependents[i];
    var isDependencyMatch = false;
    var dependencyValues = dependent.dataset.dependencyValue.split(',');

    for (var j = 0; j < dependencyValues.length; j++) {
      if (dependencyValues[j] == dependeeValue) {
        isDependencyMatch = true;
        break;
      }
    }

    var elGroup = dependent.closest('.form__group');
    if (elGroup) {
      elGroup.classList.toggle('disabled', !isDependencyMatch);
      elGroup.classList.toggle('hidden', !isDependencyMatch);
    }

    var elField = dependent.closest('.form__field');
    if (elField) {
      elField.classList.toggle('disabled', !isDependencyMatch);
      elField.classList.toggle('hidden', !isDependencyMatch);
    }

    if(dependent.classList.contains('form__textblock')) {
      dependent.classList.toggle('disabled', !isDependencyMatch);
      dependent.classList.toggle('hidden', !isDependencyMatch);
    }

    /** @NOTE If any issues related to SELECT and dependencies and required - look to latter part of this conditional. */
    /** @NOTE Also unsure why it originally was set to target non-select elements only. */
    if (dependent.tagName != 'SELECT' || (dependent.tagName == 'SELECT' && dependent.required == true)) {
      dependent.disabled = !isDependencyMatch;
    }
    // else {
    //   console.log(elField);
    //   console.log(dependeeName);
    //   console.log(dependeeValue);
    //   console.log(dependencyValues);
    //   console.log('isDependencyMatch', isDependencyMatch);
    // }

    if (dependent.hasAttribute('permadisabled')) {
      dependent.disabled = true;
    }
  }
}


/** SUBMIT
*******************************************************************************/
function initSubmits() {
  const elSubmits = document.querySelectorAll('.load-on-submit');

  for (var i = 0; i < elSubmits.length; i++) {
    let elForm = elSubmits[i].closest('form');
    elForm.addEventListener('submit', function (event) {
      if (event.currentTarget.checkValidity()) {
        document.body.classList.add('loading');
      }
    });
  }
}

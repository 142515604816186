import * as Axios from 'axios';
const axios = Axios.default;

let adsCurrentDevice;

export function initAds() {
  resizeAds();
}

export function resizeAds() {
  let adsDevice;

  if (window.innerWidth > 1399) {
    adsDevice = 'desktop';
  } else if (window.innerWidth > 991) {
    adsDevice = 'tablet';
  } else {
    adsDevice = 'mobile';
  }

  if (adsDevice != adsCurrentDevice) {
    adsCurrentDevice = adsDevice;
    const ads = document.querySelectorAll(
      `.ads_${adsCurrentDevice}--displayAd`
    );

    Array.from(ads).map((ad) => {
      if (!ad.querySelector('img')) {
        axios
          .get('/api/ads/ad/' + ad.dataset.ad.trim())
          .then(function (response) {
            if (response.statusText == 'OK') {
              // console.log(response);
              var template = document.createElement('template');
              template.innerHTML = response.data.trim();
              ad.appendChild(template.content.firstChild);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }
}

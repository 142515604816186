/** COMPOSITE FIELD DEPENDENCIES
*******************************************************************************/
var dependencyState = {};
var dependencyDependents = [];
var dependencyNames = [];

export function init() {
  dependencyDependents = document.querySelectorAll('[data-dependencies]');

  for (var i = 0; i < dependencyDependents.length; i++) {
    var elDependent = dependencyDependents[i];
    var dependencyLogic = JSON.parse(elDependent.dataset.dependencies || null);

    if (dependencyLogic['and'] || dependencyLogic['or']) {
      var expressions = dependencyLogic['and'] || dependencyLogic['or'];
      for (var j = 0; j < expressions.length; j++) {
        var expression = expressions[j];

        if (expression['and'] || expression['or']) {
          var subexpressions = expression['and'] || expression['or'];
          for (var k = 0; k < subexpressions.length; k++) {
            var subexpression = subexpressions[k];
            addDependencyName(Object.keys(subexpression)[0]);
          }
        }
        else {
          addDependencyName(Object.keys(expression)[0]);
        }
      }
    }
    else {
      addDependencyName(Object.keys(dependencyLogic)[0]);
    }
  }

  setDependees();
}

function addDependencyName(name) {
  if (!dependencyNames.includes(name)) {
    dependencyNames.push(name);
  }
}

function setDependees() {
  for (var i = 0; i < dependencyNames.length; i++) {
    var elDependees = document.querySelectorAll('[name="' + dependencyNames[i] + '"]');
    for (var j = 0; j < elDependees.length; j++) {
      var elDependee = elDependees[j];
      elDependee.addEventListener('change', onChangeDependee);
      updateDependencyState(elDependee);
    }
  }
}

function onChangeDependee(event) {
  updateDependencyState(event.currentTarget);
}

function updateDependencyState(element) {
  var obj = {};
  var key = element.name;
  var value = element.type == 'radio'
    ? document.querySelector('input[name="' + key + '"]:checked').value
    : element.value;

  obj[key] = value;
  Object.assign(dependencyState, obj);

  updateDependents();
}

function updateDependents() {
  for (var i = 0; i < dependencyDependents.length; i++) {
    var elDependent = dependencyDependents[i];
    var isDependencyMatch = false;

    var dependencyLogic = JSON.parse(elDependent.dataset.dependencies || null);

    if (dependencyLogic['and'] || dependencyLogic['or']) {

      var expressions = dependencyLogic['and'] || dependencyLogic['or'];
      for (var j = 0; j < expressions.length; j++) {
        var expression = expressions[j];

        if (expression['and'] || expression['or']) {
          var isSubDependencyMatch = false;
          var subexpressions = expression['and'] || expression['or'];
          for (var k = 0; k < subexpressions.length; k++) {
            var subexpression = subexpressions[k];
            var name = Object.keys(subexpression)[0];
            var value = subexpression[name];
            isSubDependencyMatch = dependencyMatch(name, value);

            if ((expression['or'] && isSubDependencyMatch)
              || (expression['and'] && !isSubDependencyMatch)) {
              break;
            }
          }
          isDependencyMatch = isSubDependencyMatch;
        }
        else {
          var name = Object.keys(expression)[0];
          var value = expression[name];
          isDependencyMatch = dependencyMatch(name, value);
        }

        if ((dependencyLogic['or'] && isDependencyMatch)
          || (dependencyLogic['and'] && !isDependencyMatch)) {
          break;
        }
      }
    }
    else {
      var name = Object.keys(dependencyLogic)[0];
      var value = dependencyLogic[name];
      isDependencyMatch = dependencyMatch(name, value);
    }

    var elGroup = elDependent.closest('.form__group');
    if (elGroup) {
      elGroup.classList.toggle('disabled', !isDependencyMatch);
      elGroup.classList.toggle('hidden', !isDependencyMatch);
    }

    var elField = elDependent.closest('.form__field');
    if (elField) {
      elField.classList.toggle('disabled', !isDependencyMatch);
      elField.classList.toggle('hidden', !isDependencyMatch);
    }

    var elTextBlock = elDependent.closest('.form__textblock');
    if (elTextBlock) {
      elTextBlock.classList.toggle('disabled', !isDependencyMatch);
      elTextBlock.classList.toggle('hidden', !isDependencyMatch);
    }

    // if (elDependent.tagName != 'SELECT') {
    elDependent.disabled = !isDependencyMatch;
    // }
  }
}

function dependencyMatch(name, value) {
  var values = value.split(',');
  var stateValue = dependencyState[name];
  var isDependencyMatch = false;

  for (var j = 0; j < values.length; j++) {
    if (values[j] == stateValue) {
      isDependencyMatch = true;
      break;
    }
  }

  return isDependencyMatch;
}
var accordableCurrentScale = '';

export function initAccordable() {
  accordableCurrentScale = accordableScale();
  var accordables = document.querySelectorAll('.accordable');
  for (var i = 0; i < accordables.length; i++) {
    initTable(accordables[i]);
  }
}

function accordableScale() {
  return window.innerWidth >= 1200 ? 'larger' : 'smaller';
}

export function resizeAccordable() {
  var accordableNewScale = accordableScale();
  if (accordableCurrentScale != accordableNewScale) {
    accordableCurrentScale = accordableNewScale;

    var accordables = document.querySelectorAll('.accordable');
    for (var i = 0; i < accordables.length; i++) {
      resetTable(accordables[i]);
    }
  }
}

function resetTable(elTable) {
  var removables = elTable.querySelectorAll('.accordable__filler, .accordable__panel, .accordable__toggle');
  for (var i = 0; i < removables.length; i++) {
    removables[i].parentNode.removeChild(removables[i]);
  }

  initTable(elTable);
}

function initTable(elTable) {
  if (accordableCurrentScale === 'larger') {
    elTable.dataset.colspan = elTable.querySelectorAll('thead th.display, thead th.display--lg').length;
  }
  else {
    elTable.dataset.colspan = elTable.querySelectorAll('thead th.display').length;
  }

  elTable.dataset.offset = elTable.classList.contains('accordable--offset');
  var columnsData = [];

  var columnHeadings = elTable.querySelectorAll('thead th');
  for (var i = 0; i < columnHeadings.length; i++) {
    var columnHeading = columnHeadings[i];
    var display = columnHeading.classList.contains('display');
    var label = columnHeading.classList.contains('nolabel') ? '' : columnHeading.innerText;
    var strong = columnHeading.classList.contains('strong');
    var tag = columnHeading.classList.contains('tag');

    if (tag) {
      elTable.classList.add('accordable--tag');
    }

    if (accordableCurrentScale === 'larger') {
      display = columnHeading.classList.contains('display')
        || columnHeading.classList.contains('display--lg');

      label = columnHeading.classList.contains('nolabel')
        || columnHeading.classList.contains('nolabel--lg')
        ? ''
        : columnHeading.innerText;
    }

    columnsData.push({ label, display, strong, tag });

    if (!display) {
      columnHeading.style.display = 'none';
    }
    else {
      columnHeading.style = '';
    }
  }

  // Loop through and manipulate column data
  var tableRows = elTable.querySelectorAll('.accordable__row');
  for (var i = 0; i < tableRows.length; i++) {
    var tableRow = tableRows[i];
    createPanel(tableRow, columnsData);
    addAccordionController(tableRow)
  }

  // Add filler column for accordion toggler
  var tableHeadRow = elTable.querySelector('thead tr');
  var tableHeadFiller = document.createElement('th');
  tableHeadFiller.classList.add('accordable__filler');
  tableHeadRow.appendChild(tableHeadFiller);
}

function createPanel(tableRow, columnsData) {
  var elTable = tableRow.closest('.accordable');
  var colspan = elTable.dataset.colspan;

  var tablePanelRow = document.createElement('tr');
  tablePanelRow.classList.add('accordable__panel');

  var tablePanelCell = document.createElement('td');
  tablePanelCell.setAttribute('colspan', colspan);
  tablePanelCell.style.setProperty('--colspan', colspan);

  var tablePanelFiller = document.createElement('td');
  tablePanelFiller.classList.add('accordable__filler');

  tablePanelRow.appendChild(tablePanelCell);
  tablePanelRow.appendChild(tablePanelFiller);
  tableRow.insertAdjacentElement('afterend', tablePanelRow);

  var content = document.createElement('div');
  content.classList.add('accordable__content');
  tablePanelCell.appendChild(content);

  var tableCells = tableRow.querySelectorAll('td');
  for (var i = 0; i < tableCells.length; i++) {
    var contentItem = newContentItem(tableCells[i], columnsData[i]);
    if (contentItem) {
      content.appendChild(contentItem);
    }
  }
}

function newContentItem(cell, data) {
  if (!data.display) {
    cell.style.display = 'none';
  }
  else {
    cell.style = '';
  }

  if (data.strong) {
    cell.classList.add('strong');
  }

  if (data.tag) {
    cell.classList.add('tag');
    if (!cell.querySelector('span') && cell.innerHTML) {
      cell.innerHTML = '<span>' + cell.innerHTML + '</span>';
    };
  }

  if (data.display) {
    if (data.label) {
      cell.setAttribute('data-label', data.label);
    }

    return false;
  }

  var elTable = cell.closest('.accordable');
  var colspan = elTable.dataset.colspan;
  var offset = elTable.dataset.offset;

  var contentItem = document.createElement('div');
  contentItem.classList.add('accordable__contentItem');

  if (offset == 'true') {
    contentItem.style.width = 'calc(100% / ' + (colspan - 1) + ')';
  } else {
    contentItem.style.width = 100 / colspan + "%";
  }

  var contentItemKey = document.createElement('div');
  contentItemKey.classList.add('key');
  contentItemKey.innerHTML = data.label;
  contentItem.appendChild(contentItemKey);

  var contentItemValue = document.createElement('div');
  contentItemValue.classList.add('value');
  contentItemValue.innerHTML = cell.innerHTML;
  contentItem.appendChild(contentItemValue);

  return contentItem;
}

function addAccordionController(tableRow) {
  var container = document.createElement('td');
  container.classList.add('accordable__toggle');
  var controller = document.createElement('button');
  container.appendChild(controller);
  tableRow.appendChild(container);
  controller.addEventListener('click', onAccordableToggleClick);
}

function onAccordableToggleClick(event) {
  var tableRow = event.currentTarget.parentNode.parentNode;
  if (!tableRow.classList.contains('accordable__row')) return;

  event.preventDefault();

  if (tableRow.classList.contains('active')) {
    tableRow.classList.remove('active');
    return;
  }

  var elTable = event.currentTarget.closest('.accordable');

  var accordions = elTable.querySelectorAll('.accordable__row.active');
  for (var i = 0; i < accordions.length; i++) {
    accordions[i].classList.remove('active');
  }

  tableRow.classList.toggle('active');
}
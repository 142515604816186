import * as Axios from 'axios';
const axios = Axios.default;

export function initMemberCheck() {

  // memberUrl and cookieHash should be set in header of _layout.twig
  const memberUrl = document.querySelector('meta[name="memberUrl"]')?.content || "";
  const cookieHash = document.querySelector('meta[name="memberHash"]')?.content || "";

  if ( cookieHash.length < 1 || memberUrl.length < 1 ) return false;

  /* check if user is logged in member area */
  axios.get( memberUrl + '/api/cookie/' + cookieHash)
  .then(function (response) {

    if (response.data?.first_name?.length > 1) {
      const memberElements = document.querySelectorAll('.js-member-check');
      for (var i = 0; i < memberElements.length; i++) {
        updateMemberData(memberElements[i], response.data);
      }
    }
  })
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // always executed
  });

}

function updateMemberData(element, data) {

  if (data?.first_name.length < 1) return false;

  // display different menu if logged in
  if (element.classList.contains('hidden')) {
    element.classList.remove("hidden");
  } else {
    element.classList.add("hidden");
  }

  // update user menu to reflect user logged in
  if (element.id === "auth-menu") {
    let nameObject = element.querySelector(".dropdown__trigger > span") || "";
    let mobileNameObject = element.getElementsByClassName("dropdown__list")?.[0] || "";
    let menuObject = element.getElementsByClassName("dropdown__listitem")?.[1] || "";
    // change name and remove
    if (mobileNameObject.innerText.length > 1) mobileNameObject.dataset.label = data.first_name + ' ' + data.last_name;
    if (nameObject.innerText.length > 1) nameObject.innerText = data.first_name + ' ' + data.last_name;
    if (menuObject.innerText.length > 1 && data.representative !== true) menuObject.remove();
  }
}



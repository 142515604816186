export function collapsible() {
  const collapsible = document.getElementById('collapsible');
  const collapsibleBtnDown = document.getElementById('collapsibleBtnDown');
  const collapsibleBtnUp = document.getElementById('collapsibleBtnUp');

  if (collapsibleBtnDown && collapsibleBtnDown.display !== 'none') {
    collapsibleBtnDown.addEventListener('click', function () {
      collapsible.classList.contains('copyBox--collapsible__closed') ?
        collapsible.classList.replace('copyBox--collapsible__closed', 'copyBox--collapsible__open') : ''
    });
  }

  if (collapsibleBtnUp && collapsibleBtnUp.display !== 'none') {
    collapsibleBtnUp.addEventListener('click', function () {
      collapsible.classList.contains('copyBox--collapsible__open') ?
        collapsible.classList.replace('copyBox--collapsible__open', 'copyBox--collapsible__closed') : ''
    });
  }
}